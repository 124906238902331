<template>
  <v-container class="help-content">
    <v-row dense>
      <v-col cols="7">
        <v-img src="images/custom12.png" />
      </v-col>
      <v-col>
        <v-row dense>
          <v-col cols="1"></v-col>
          <v-col>
            <v-row dense>
                <v-col cols="1">
                    1.
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-model-5") }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-model-6-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-model-6-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-model-7-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-model-7-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-model-8-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-model-8-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-model-9-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-model-9-2") }}</v-col>
            </v-row>
            <v-row>
                <v-col>{{ $t("Helps.custom-model-tbc") }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
