var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_vm._v(" hello "),_c('v-row',{attrs:{"dense":""}},[(_vm.data.action == 'MORE_ACTIONS' && !_vm.flashAdded && !_vm.parentMsgCount)?_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[(!_vm.actionExp)?_c('v-container',{staticClass:"pa-0"},[(_vm.clicked1to1 === 0)?_c('v-container',{staticClass:"pb-1 pt-1"},[_c('h3',[_vm._v(_vm._s(_vm.$t("DrillPlatform.1toMore")))]),_vm._l((_vm.paramdata.action_det.action),function(act){return _c('v-row',{key:act.actionExp,attrs:{"dense":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(
                  act.actionName == act.parentAction &&
                    act.show > 0 &&
                    (act.maxStaff > 1 ||
                      (act.applyAll > 0 && act.maxStaff > 0))
                )?_c('v-col',{class:{ 'action-hover': hover },on:{"click":function($event){$event.stopPropagation();_vm.actionExp = act.displayKey;
                  _vm.selectedAction(act.displayKey);}}},[_vm._v(_vm._s(_vm.$t(act.displayKey)))]):_vm._e()]}}],null,true)})],1)})],2):_vm._e(),(_vm.clicked1to1 < 1)?_c('v-divider'):_vm._e(),_c('v-container',{staticClass:"pb-1"},[_c('h3',[_vm._v(_vm._s(_vm.$t("DrillPlatform.1to1")))]),_vm._l((_vm.paramdata.action_det.action),function(act){return _c('v-row',{key:act.actionExp,attrs:{"dense":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [(
                  act.actionName == act.parentAction &&
                    act.show > 0 &&
                    act.maxStaff == 1 &&
                    act.applyAll == 0
                )?_c('v-col',{class:{ 'action-hover': hover },on:{"click":function($event){$event.stopPropagation();_vm.actionExp = act.displayKey;
                  _vm.selectedAction(act.displayKey);}}},[_vm._v(_vm._s(_vm.$t(act.displayKey)))]):_vm._e()]}}],null,true)})],1)})],2),(_vm.clicked1to1 < 1)?_c('v-divider'):_vm._e(),(_vm.clicked1to1 === 0)?_c('v-container',{staticClass:"pb-1"},[_c('h3',[_vm._v(_vm._s(_vm.$t("DrillPlatform.management")))]),_vm._l((_vm.paramdata.action_det.action),function(act){return _c('v-row',{key:act.actionExp,attrs:{"dense":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [(
                  act.actionName == act.parentAction &&
                    act.show > 0 &&
                    act.maxStaff < 1
                )?_c('v-col',{class:{ 'action-hover': hover },on:{"click":function($event){$event.stopPropagation();_vm.actionExp = act.displayKey;
                  _vm.selectedAction(act.displayKey);}}},[_vm._v(_vm._s(_vm.$t(act.displayKey)))]):_vm._e()]}}],null,true)})],1)})],2):_vm._e()],1):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }