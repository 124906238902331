<template>
  <v-container>
      <v-container class="report-frame">
        <v-row  class="report-result-spacer">
          <v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col>
            <v-card width="100%">
              <v-card-text>
                <div v-if="resultSummary()=='in-progress'">
                  <h2>{{$t('DrillReport.result-'+resultSummary())}}</h2>
                </div>
                <div v-if="resultSummary()!='in-progress'">
                  <h2>{{$t('DrillReport.result-'+resultSummary())}} {{$t('DrillReport.result-general-'+resultGeneral(this.data.content.summary[0].teamAvg.total, this.data.content.current.teamAvg.total))}} {{$t('DrillReport.result-efficiency-'+resultEfficiency(this.data.content.summary[0].teamAvg.efficiency, this.data.content.current.teamAvg.efficiency))}} {{$t('DrillReport.result-adaptable-'+resultAdaptable(data.content.analysis.style.desire_intent.count+data.content.analysis.style.desire_actual.count+data.content.analysis.style.intent_actual.count, data.content.analysis.style.desire_intent.positive+data.content.analysis.style.desire_actual.positive+data.content.analysis.style.intent_actual.positive))}}</h2>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col>
            <v-card width="100%">
              <v-card-title>
                {{$t('DrillReport.result-explain')}}
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="10"></v-col>
                  <v-col cols="2" class="text-end">{{$t('DrillFunnel.overallValue')}}</v-col>
                </v-row>
                <v-row dense align="center">
                  <v-col cols="10">
                    <v-progress-linear
                      background-color="grey lighten-2"
                      :value="targetPercent()"
                      color="black"
                      height="10"
                    >
                    </v-progress-linear>
                  </v-col>
                  <v-col cols="2" class="text-end">{{$t('DrillPlatform.dollarSign')}}{{data.content.valueToComplete}}</v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="10"></v-col>
                  <v-col cols="2" class="text-end">{{$t('DrillFunnel.value')}}</v-col>
                </v-row>
                <v-row dense align="center">
                  <v-col cols="10">
                    <v-progress-linear
                      background-color="grey lighten-2"
                      :value="valuePercent()"
                      :color="valueColor((data.content.orderValue*data.content.orderStatus.ordersCompleted)*100/data.content.valueToComplete)"
                      height="10"
                    >
                    </v-progress-linear>
                  </v-col>
                  <v-col cols="2" class="text-end">{{$t('DrillPlatform.dollarSign')}}{{data.content.orderValue*data.content.orderStatus.ordersCompleted}}</v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="10"></v-col>
                  <v-col cols="2" class="text-end">{{$t('DrillFunnel.orders')}}</v-col>
                </v-row>
                <v-row dense align="center">
                  <v-col cols="10">
                  </v-col>
                  <v-col cols="2" class="text-end">{{data.content.orderStatus.ordersCompleted}}</v-col>
                </v-row>
                {{$t('DrillReport.result-revenue-explain')}}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col>
            <v-card width="100%">
              <v-card-title>
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="1"></v-col>
                  <v-col cols="2">{{$t('DrillReport.result-team-efficiency')}}</v-col>
                  <v-col cols="2" class="text-center report-result-final">{{$t('DrillReport.result-final-value')}}</v-col>
                  <v-col cols="6">
                      <v-progress-linear
                        background-color="grey lighten-2"
                        :value="data.content.current.teamAvg.efficiency"
                        color="brown"
                        height="15"
                      >
                      <template>
                        <strong class="report-result-text">{{data.content.current.teamAvg.efficiency}}</strong>
                      </template>
                      </v-progress-linear>                        
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="1"></v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="2" class="text-center report-result-initial">{{$t('DrillReport.result-initial-value')}}</v-col>
                  <v-col cols="6">
                      <v-progress-linear
                        background-color="grey lighten-2"
                        :value="data.content.summary[0].teamAvg.efficiency"
                        color="blue"
                        height="15"
                      >
                      <template>
                        <strong>{{data.content.summary[0].teamAvg.efficiency}}</strong>
                      </template>
                      </v-progress-linear>                        
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row>
                  <v-col> </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="1"></v-col>
                  <v-col cols="2">{{$t('DrillReport.result-team-skills')}}</v-col>
                  <v-col cols="2" class="text-center report-result-final">{{$t('DrillReport.result-final-value')}}</v-col>
                  <v-col cols="6">
                      <v-progress-linear
                        background-color="grey lighten-2"
                        :value="data.content.current.teamAvg.skills"
                        color="brown"
                        height="15"
                      >
                      <template>
                        <strong class="report-result-text">{{data.content.current.teamAvg.skills}}</strong>
                      </template>
                      </v-progress-linear>                        
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="1"></v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="2" class="text-center report-result-initial">{{$t('DrillReport.result-initial-value')}}</v-col>
                  <v-col cols="6">
                      <v-progress-linear
                        background-color="grey lighten-2"
                        :value="data.content.summary[0].teamAvg.skills"
                        color="blue"
                        height="15"
                      >
                      <template>
                        <strong>{{data.content.summary[0].teamAvg.skills}}</strong>
                      </template>
                      </v-progress-linear>                        
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row>
                  <v-col> </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="1"></v-col>
                  <v-col cols="2">{{$t('DrillReport.result-team-morale')}}</v-col>
                  <v-col cols="2" class="text-center report-result-final">{{$t('DrillReport.result-final-value')}}</v-col>
                  <v-col cols="6">
                      <v-progress-linear
                        background-color="grey lighten-2"
                        :value="data.content.current.teamAvg.morale"
                        color="brown"
                        height="15"
                      >
                      <template>
                        <strong class="report-result-text">{{data.content.current.teamAvg.morale}}</strong>
                      </template>
                      </v-progress-linear>                        
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="1"></v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="2" class="text-center report-result-initial">{{$t('DrillReport.result-initial-value')}}</v-col>
                  <v-col cols="6">
                      <v-progress-linear
                        background-color="grey lighten-2"
                        :value="data.content.summary[0].teamAvg.morale"
                        color="blue"
                        height="15"
                      >
                      <template>
                        <strong>{{data.content.summary[0].teamAvg.morale}}</strong>
                      </template>
                      </v-progress-linear>                        
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row>
                  <v-col> </v-col>
                </v-row>
                <!-- <v-row dense>
                  <v-col cols="1"></v-col>
                  <v-col cols="2">{{$t('DrillReport.result-team-total')}}</v-col>
                  <v-col cols="2" class="text-center report-result-final">{{$t('DrillReport.result-final-value')}}</v-col>
                  <v-col cols="6">
                      <v-progress-linear
                        background-color="grey lighten-2"
                        :value="data.content.current.teamAvg.total"
                        color="brown"
                        height="15"
                      >
                      <template>
                        <strong class="report-result-text">{{data.content.current.teamAvg.total}}</strong>
                      </template>
                      </v-progress-linear>                        
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="1"></v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="2" class="text-center report-result-initial">{{$t('DrillReport.result-initial-value')}}</v-col>
                  <v-col cols="6">
                      <v-progress-linear
                        background-color="grey lighten-2"
                        :value="data.content.summary[0].teamAvg.total"
                        color="blue"
                        height="15"
                      >
                      <template>
                        <strong>{{data.content.summary[0].teamAvg.total}}</strong>
                      </template>
                      </v-progress-linear>                        
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="1"></v-col>
                  <v-col>
                    {{$t('DrillReport.result-team-explain')}}
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-container>
  </v-container>
</template>

<script>
export default {
    name: "ReportResult",
    data() {
      return{
      } 
    },
    props: {
      data: {},
    },
    methods: {
      resultSummary() {
        let result = 'in-progress'
//        result = 'timeout'
        if (this.data.action!='INTRODUCTION' && this.data.action != 'SELECT_STYLE' && this.data.action != 'MORE_ACTIONS'){
          switch(this.data.action){
            case 'TIME_OUT':
            case 'FAILED':
              if (this.data.content.valueToComplete == (this.data.content.orderValue*this.data.content.orderStatus.ordersCompleted)){
                result = 'complete'
              } else if (this.data.content.valueToComplete < (this.data.content.orderValue*this.data.content.orderStatus.ordersCompleted)){
                result = 'over-target'
              } else {
                result = 'timeout'
              }
              break;
            case 'COMPLETED':
              if (this.data.content.valueToComplete == (this.data.content.orderValue*this.data.content.orderStatus.ordersCompleted)){
                result = 'complete'
              } else if (this.data.content.valueToComplete < (this.data.content.orderValue*this.data.content.orderStatus.ordersCompleted)){
                result = 'over-target'
              }
              break;
          }
        }
        return result
      },
      resultGeneral(b, f){
        let result = ''
        if (f>= b){
          if (((f-b)/b) > 0.2){
            result = 'vh'
          } else {
            result = 'h'
          }
        } else {
          if (((b-f)/b) <= 0.2){
            result = 'l'
          } else {
            result = 'vl'
          }
        }
        return result

      },
      resultEfficiency(b, f){
        let result = ''
        if (f>= b){
          if (((f-b)/b) > 0.3){
            result = 'vh'
          } else {
            result = 'h'
          }
        } else {
          if (((b-f)/b) <= 0.2){
            result = 'l'
          } else {
            result = 'vl'
          }
        }
        return result

      },
      resultAdaptable(s, e){
        let c = 1
        let p = 0
        let result = ''
        if (e>0 && s>0){
          p = e/s
          p = (p>=1)?1:p
          c = Math.round(p*10)
        }
        if (c>8){
          result = 'vh'
        } else if (c>5){
          result = 'h'
        } else if (c>3){
          result = 'l'
        } else {
          result = 'vl'
        }
        return result
      },
      targetPercent(){
        let p=100
        if ((this.data.content.orderValue*this.data.content.orderStatus.ordersCompleted)>this.data.content.valueToComplete){
          p = this.data.content.valueToComplete*100/this.data.content.orderValue*this.data.content.orderStatus.ordersCompleted
        }
        return p
      },
      valuePercent(){
        let p=100
        if ((this.data.content.orderValue*this.data.content.orderStatus.ordersCompleted)<this.data.content.valueToComplete){
          p = this.data.content.orderValue*this.data.content.orderStatus.ordersCompleted*100/this.data.content.valueToComplete
        }
        return p
      },
      valueColor(v) {
        let c = "";
        // if (v > 80) {
        //   c = "#27AE60";
        // } else if (v > 60) {
        //   c = "#2ECC71";
        // } else if (v > 40) {
        //   c = "#F1C40F";
        // } else if (v > 20) {
        //   c = "#F39C12";
        // } else {
        //   c = "#E74C3C";
        // }
        if (v > 75) {
          c = "#27AE60";
        } else if (v > 50) {
          c = "#2ECC71";
        } else if (v > 25) {
          c = "#F39C12";
        } else {
          c = "#E74C3C";
        }
        return c;
      },
    } 
}
</script>

<style>

</style>