var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 ma-0"},[_c('v-card',{staticClass:"mt-3 mb-3 pt-0 pb-0",attrs:{"dense":"","color":"#f5f6f7"}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("Drill.welcome"))+_vm._s(_vm.$t("Drill.drill1"))+_vm._s(_vm.$t("Drill.commer"))+_vm._s(_vm.$t("Drill.drill2"))+_vm._s(_vm.$t("Drill.commer"))+_vm._s(_vm.$t("Drill.drill3"))+_vm._s(_vm.$t("Drill.welcome1"))+" ")])],1),_c('v-card',{attrs:{"dense":"","color":"#f5f6f7"}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('h2',[_vm._v(_vm._s(_vm.$t("Nav.Drill")))])])],1),_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',[(!_vm.editName)?_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){$event.stopPropagation();_vm.editName = true;
                  _vm.oldName = _vm.data.user.userName;}}},[_c('span',{staticClass:"mdi mdi-account"}),_c('span',[_vm._v(_vm._s(_vm.data.user.userName))])]):_vm._e(),(_vm.editName)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"small":"","prepend-inner-icon":"mdi mdi-account","outlined":""},model:{value:(_vm.data.user.userName),callback:function ($$v) {_vm.$set(_vm.data.user, "userName", $$v)},expression:"data.user.userName"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"mdi mdi-check",on:{"click":function($event){_vm.$emit('Update-Name', _vm.data.user);
                      _vm.editName = false;}}}),_c('span',{staticClass:"mdi mdi-restore",on:{"click":function($event){_vm.editName = false;
                      _vm.data.user.userName = _vm.oldName;
                      _vm.oldName = '';}}})])],1):_vm._e()],1),_c('v-col',[_c('v-btn',{attrs:{"elevation":"0"}},[_c('span',{staticClass:"mdi mdi-domain"}),_c('span',[_vm._v(_vm._s(_vm.data.user.companyName))])])],1)],1)],1),_c('v-col',{staticClass:"text-end"},[(_vm.data.user.runOne && _vm.data.user.instructor == 1)?_c('v-btn',{staticStyle:{"font-weight":"bold","background":"black"},attrs:{"rounded":""},on:{"click":function($event){return _vm.$emit('Drill-Reset', _vm.ctl)}}},[_c('span',{staticClass:"msg-btn"},[_vm._v(_vm._s(_vm.$t("Drill.reset")))])]):_vm._e()],1)],1),_c('br'),_c('v-divider'),_c('br'),(_vm.data.user.runOne)?_c('div',[_c('v-row',_vm._l((_vm.data.runs),function(run){return _c('v-col',{key:run.seq,staticClass:"align-center"},[_c('v-card',{staticStyle:{"overflow":"display"},attrs:{"height":"300px","width":"300px","img":require(run.seq - _vm.ctl.completedDrill <= 1
                  ? '../../assets/list-back-1.gif'
                  : '../../assets/list-back-2.gif')},on:{"click":function($event){$event.stopPropagation();return _vm.drillClicked(run)}}},[_c('v-card-text',[_c('div',{staticStyle:{"position":"absolute","top":"50px","left":"150px","font-size":"1.2em","font-weight":"bold"}},[(run.seq <= _vm.ctl.completedDrill * 1 + 1)?_c('v-btn',{staticClass:"progress-btn",attrs:{"block":"","elevation":"8","outlined":"","rounded":""}},[(
                        run.seq - _vm.ctl.completedDrill === 1 &&
                          _vm.data.runs[run.seq - 1].drillStatus < 200
                      )?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t("Drill.start")))]):_vm._e(),(
                        run.seq - _vm.ctl.completedDrill === 1 &&
                          _vm.data.runs[run.seq - 1].drillStatus > 100
                      )?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t("Drill.continue")))]):_vm._e(),(run.seq <= _vm.ctl.completedDrill)?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t("Drill.complete")))]):_vm._e()]):_vm._e()],1),_c('div',{staticStyle:{"position":"absolute","top":"150px","left":"25px"}},[_c('span',{staticStyle:{"font-size":"3em","font-weight":"bold"}},[_vm._v("0"+_vm._s(run.seq))])]),_c('div',{staticStyle:{"position":"absolute","top":"135px","left":"115px"}},[_c('span',{staticStyle:{"font-size":"1.3em","font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t("Drill.drill" + run.seq)))])]),_c('div',{staticStyle:{"position":"absolute","top":"250px","left":"140px"}},[_c('span',{staticStyle:{"font-size":"1em","font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t("Drill.drillDuration"))+": "+_vm._s(run.duration))])])])],1)],1)}),1)],1):_c('div',[_c('v-row',[_c('v-col')],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.$t("Drill.noRun")))])])],1)],1),_c('v-card',{attrs:{"dense":"","color":"#f5f6f7"}},[_c('v-card-text',[_vm._v(_vm._s(_vm.$t("Drill.note")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }