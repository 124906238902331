<template>
  <v-container class="help-content">
    <v-row dense>
      <v-col cols="7">
        <v-img src="images/custom5.png" />
      </v-col>
      <v-col>
        <v-row dense>
          <v-col cols="1"></v-col>
          <v-col>
            <v-row dense>
                <v-col cols="1">
                    1.
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-task-4") }}
                </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
              </v-col>
              <v-col cols="3">
                {{ $t("Helps.custom-task-4-1") }}
              </v-col>
              <v-col>{{ $t("Helps.custom-task-4-2") }}</v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
              </v-col>
              <v-col cols="3">
                {{ $t("Helps.custom-task-5-1") }}
              </v-col>
              <v-col>{{ $t("Helps.custom-task-5-2") }}</v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
              </v-col>
              <v-col cols="3">
                {{ $t("Helps.custom-task-6-1") }}
              </v-col>
              <v-col>{{ $t("Helps.custom-task-6-2") }}</v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
              </v-col>
              <v-col cols="3">
                {{ $t("Helps.custom-task-7-1") }}
              </v-col>
              <v-col>{{ $t("Helps.custom-task-7-2") }}</v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                2.
              </v-col>
              <v-col>
                {{ $t("Helps.custom-task-8-1") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
              </v-col>
              <v-col>
                {{ $t("Helps.custom-task-8-2") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
              </v-col>
              <v-col>
                {{ $t("Helps.custom-task-8-3") }}
              </v-col>
            </v-row>
            <v-row>
                <v-col>{{ $t("Helps.custom-task-9") }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
