<template>
<v-container>
  <h1>Sales Funal</h1>
  <v-btn @click="$emit('signed-off')">Logout</v-btn>  
</v-container>
</template>

<script>
export default {
    name: 'SalesFunal'
}
</script>

<style>

</style>