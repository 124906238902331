<template>
  <v-container class="pa-2">
    <v-img
      src="images/signin.png"
    />
    <v-divider></v-divider>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col>
        {{$t("Helps.signin-introduction")}}
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col cols="1">
        1.
      </v-col>
      <v-col>
        {{$t("Helps.signin-1")}}
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col cols="1">
        2.
      </v-col>
      <v-col>
        {{$t("Helps.signin-2")}}
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col cols="1">
        3.
      </v-col>
      <v-col>
        {{$t("Helps.signin-3")}}
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-img
      src="images/mainpage.png"
    />
    <v-divider></v-divider>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col>
        {{$t("Helps.mainpage-introduction")}}
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col cols="1">
        1.
      </v-col>
      <v-col>
        {{$t("Helps.mainpage-1")}}
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-img
      src="images/mainmenu.png"
    />
    <v-divider></v-divider>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col>
        {{$t("Helps.mainmenu-introduction")}}
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col cols="1">
        1.
      </v-col>
      <v-col>
        {{$t("Helps.mainmenu-1")}}
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col cols="1">
        2.
      </v-col>
      <v-col>
        {{$t("Helps.mainmenu-2")}}
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col cols="1">
        3.
      </v-col>
      <v-col>
        {{$t("Helps.mainmenu-3")}}
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {

}
</script>

<style>

</style>