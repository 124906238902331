<template>
  <v-container class="help-content">
    <v-row dense>
      <v-col cols="7">
        <v-img src="images/useradmin5.png" />
      </v-col>
      <v-col>
        <v-row dense>
          <v-col cols="1"></v-col>
            <v-col>
              <v-row dense>
                <v-col>
                  {{ $t("Helps.useradmin-user") }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="1">
                  1.
                </v-col>
                <v-col>
                  {{ $t("Helps.useradmin-user-4") }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col>
                  {{ $t("Helps.useradmin-user-4-1") }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col>
                  {{ $t("Helps.useradmin-user-4-2") }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col>
                  {{ $t("Helps.useradmin-user-4-3") }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col>
                  {{ $t("Helps.useradmin-user-4-4") }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="1">
                    2.
                </v-col>
                <v-col>
                  {{ $t("Helps.useradmin-user-5-1") }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col>
                  {{ $t("Helps.useradmin-user-5-2") }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col>
                  {{ $t("Helps.useradmin-user-5-3") }}
                </v-col>
              </v-row>
            </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-col> 
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
