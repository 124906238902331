<template>
  <v-container class="help-content">
    <v-row dense>
      <v-col cols="7" class="help-img">
        <v-img src="images/drillpage20.png" />
      </v-col>
      <v-col>
        <v-row dense>
          <v-col cols="1"></v-col>
          <v-col>
            <v-row dense>
              <v-col>
                {{ $t("Helps.drill-feedback-intro") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                1.
              </v-col>
              <v-col>
                {{ $t("Helps.drill-feedback-1") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                
              </v-col>
              <v-col>
                {{ $t("Helps.drill-feedback-2") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                
              </v-col>
              <v-col>
                {{ $t("Helps.drill-feedback-3") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                2.
              </v-col>
              <v-col>
                {{ $t("Helps.drill-feedback-4") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                3.
              </v-col>
              <v-col>
                {{ $t("Helps.drill-feedback-5") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                4.
              </v-col>
              <v-col>
                {{ $t("Helps.drill-feedback-6") }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
