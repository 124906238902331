<template>
  <v-container class="help-content">
    <v-row dense>
      <v-col cols="7" class="help-img">
            <v-img src="images/drillpage5.png" width="300px"/>
      </v-col>
      <v-col>
        <v-row dense>
            <v-col cols="1"></v-col>
            <v-col>
                <v-row dense>
                    <v-col>
                        {{ $t("Helps.drill-staff1-introduction") }}
                    </v-col>
                </v-row>
                <v-row dense>
                <v-col cols="1">
                    1.
                </v-col>
                <v-col>
                    {{ $t("Helps.drill-staff1-1") }}
                </v-col>
                </v-row>
                <v-row dense>
                <v-col cols="1">
                    2.
                </v-col>
                <v-col>
                    {{ $t("Helps.drill-staff1-2") }}
                </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="1"></v-col>
                    <v-col>
                        <v-row dense>
                            <v-col cols="3">{{$t("Helps.drill-staff1-2-1")}}</v-col>
                            <v-col>{{$t("Helps.drill-staff1-2-1-d")}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="3">{{$t("Helps.drill-staff1-2-2")}}</v-col>
                            <v-col>{{$t("Helps.drill-staff1-2-2-d")}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="3">{{$t("Helps.drill-staff1-2-3")}}</v-col>
                            <v-col>{{$t("Helps.drill-staff1-2-3-d")}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="3">{{$t("Helps.drill-staff1-2-4")}}</v-col>
                            <v-col>{{$t("Helps.drill-staff1-2-4-d")}}</v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row dense>
                <v-col cols="1">
                    3.
                </v-col>
                <v-col>
                    {{ $t("Helps.drill-staff1-3") }}
                </v-col>
                </v-row>
                <v-row dense>
                <v-col cols="1">
                    4.
                </v-col>
                <v-col>
                    {{ $t("Helps.drill-staff1-4") }}
                </v-col>
                </v-row>
            </v-col>
            <v-col cols="1"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
