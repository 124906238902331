<template>
  <v-container class="help-content">
    <v-row dense>
      <v-col cols="7" class="center-text">
        <v-img src="images/drillpage2.png" />
      </v-col>
      <v-col>
        <v-row dense>
            <v-col cols="1"></v-col>
            <v-col>
                <v-row dense>
                    <v-col>
                        {{ $t("Helps.drill-plate-introduction") }}
                    </v-col>
                </v-row>
                <v-row dense>
                <v-col cols="1">
                    1.
                </v-col>
                <v-col>
                    {{ $t("Helps.drill-plate-1") }}
                </v-col>
                </v-row>
                <v-row dense>
                <v-col cols="1">
                    2.
                </v-col>
                <v-col>
                    {{ $t("Helps.drill-plate-2") }}
                </v-col>
                </v-row>
                <v-row dense>
                <v-col cols="1">
                    3.
                </v-col>
                <v-col>
                    {{ $t("Helps.drill-plate-3") }}
                </v-col>
                </v-row>
                <v-row dense>
                <v-col cols="1">
                    4.
                </v-col>
                <v-col>
                    {{ $t("Helps.drill-plate-4") }}
                </v-col>
                </v-row>
                <v-row dense>
                <v-col cols="1">
                    5.
                </v-col>
                <v-col>
                    {{ $t("Helps.drill-plate-5") }}
                </v-col>
                </v-row>
            </v-col>
            <v-col cols="1"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
