<template>
  <v-container class="help-content">
    <v-row dense>
      <v-col cols="7">
        <v-img src="images/custom9.png" width="450px"/>
      </v-col>
      <v-col>
        <v-row dense>
          <v-col cols="1"></v-col>
          <v-col>
            <v-row dense>
                <v-col cols="1">
                    1.
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-staff-4") }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-staff-5-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-staff-5-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-staff-6-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-staff-6-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-staff-7-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-staff-7-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-staff-8-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-staff-8-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-staff-9-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-staff-9-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-staff-10-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-staff-10-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-staff-11-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-staff-11-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-staff-12-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-staff-12-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-staff-13-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-staff-13-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-staff-14-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-staff-14-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                    2.
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-staff-15-1") }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-staff-15-2") }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-staff-15-3") }}
                </v-col>
            </v-row>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
