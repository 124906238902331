<template>
  <v-container class="help-content">
    <v-row dense>
      <v-col cols="7">
        <v-img src="images/custom7.png" />
      </v-col>
      <v-col>
        <v-row dense>
          <v-col cols="1"></v-col>
          <v-col>
            <v-row dense>
                <v-col cols="1">
                    1.
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-plate-3") }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-plate-4-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-plate-4-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-plate-5-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-plate-5-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-plate-6-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-plate-6-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-plate-7-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-plate-7-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-plate-8-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-plate-8-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-plate-9-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-plate-9-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                    2.
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-plate-10-1") }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-plate-10-2") }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-plate-10-3") }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col>
                    {{ $t("Helps.custom-plate-11") }}
                </v-col>
            </v-row>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
