<template>
  <v-container class="help-content">
    <v-row dense>
      <v-col cols="7" class="help-img">
        <v-img src="images/drillpage19.png" />
      </v-col>
      <v-col>
        <v-row dense>
          <v-col cols="1"></v-col>
          <v-col>
            <v-row dense>
              <v-col>
                {{ $t("Helps.drill-lead-intro") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                1.
              </v-col>
              <v-col>
                {{ $t("Helps.drill-lead-12") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                2.
              </v-col>
              <v-col>
                {{ $t("Helps.drill-lead-13") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
              </v-col>
              <v-col>
                {{ $t("Helps.drill-lead-14") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
              </v-col>
              <v-col>
                {{ $t("Helps.drill-lead-15") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1">
              </v-col>
              <v-col>
                {{ $t("Helps.drill-lead-16") }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
