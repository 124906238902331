<template>
  <v-container class="help-content">
    <v-row dense>
      <v-col cols="7">
        <v-img src="images/custom14.png" />
      </v-col>
      <v-col>
        <v-row dense>
          <v-col cols="1"></v-col>
          <v-col>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-model-13-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-model-13-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col cols="3">
                    {{ $t("Helps.custom-model-14-1") }}
                </v-col>
                <v-col>{{ $t("Helps.custom-model-14-2") }}</v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                    2.
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-model-15-1") }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-model-15-2") }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="1">
                </v-col>
                <v-col>
                    {{ $t("Helps.custom-model-15-3") }}
                </v-col>
            </v-row>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
