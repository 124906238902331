<template
  ><div>
    <v-dialog persistent v-model="mod" fullscreen>
      <v-card :img="require('../../assets/skyline.png')" class="pa-0">
        <v-card-text>
          <v-row style="height: 87vh">
            <v-col>
              <v-row
                ><v-col><br /><br /></v-col
              ></v-row>
                  <DrillMessageBox
                    :avatar="require('../../assets/bossoffice.jpg')"
                    :msg="$t('Drill.meetBoss_1_1')"
                    :btnText="$t('Drill.knock')"
                    @btn-click="$emit('meetNext')"
                  />
              <v-row>
                <v-col><br /></v-col
              ></v-row>
            </v-col>
          </v-row>
          <v-row class="pa-0" style="height: 13vh">
            <v-col cols="1"></v-col>
            <v-col cols="6" align="center">
              <v-row class="login-text" align="center">
                <v-col cols="5"
                  ><span>{{ $t("portalname") }}</span></v-col
                >
                <v-divider vertical thickness="3" color="#ddbd82"> </v-divider>
                <v-col cols="7" class="login-title text-start"
                  >                <p style="line-height: 1.4">
                {{ $t("homemessage1") }}<br />
                {{ $t("homemessage2") }}<br />
                {{ $t("homemessage3") }}
                </p>
</v-col
                >
              </v-row>
            </v-col>
            <v-col cols="5"></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DrillMessageBox from "./DrillMessageBox";
export default {
  name: "DrillMeetBoss_1",
  components: {
    DrillMessageBox,
  },
  data() {
    return {
      mod: "OK",
    };
  },
  methods: {
    sHeight(v) {
      return (screen.height - 190) * v.toFixed() + "px";
    },
  },
};
</script>

<style></style>
