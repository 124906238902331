<template>
  <v-container class="help-content">
    <v-row dense>
      <v-col cols="7">
        <v-img src="images/custom2.png" />
      </v-col>
      <v-col>
        <v-row dense>
          <v-col cols="1"></v-col>
          <v-col>
            <v-row dense>
              <v-col cols="1">
                1.
              </v-col>
              <v-col>
                {{ $t("Helps.custom-product-3") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1"> </v-col>
              <v-col cols="3">
                {{ $t("Helps.custom-product-4-1") }}
              </v-col>
              <v-col>
                {{ $t("Helps.custom-product-4-2") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1"> </v-col>
              <v-col cols="3">
                {{ $t("Helps.custom-product-5-1") }}
              </v-col>
              <v-col>
                {{ $t("Helps.custom-product-5-2") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1"> </v-col>
              <v-col cols="3">
                {{ $t("Helps.custom-product-6-1") }}
              </v-col>
              <v-col>
                {{ $t("Helps.custom-product-6-2") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1"> </v-col>
              <v-col cols="3">
                {{ $t("Helps.custom-product-7-1") }}
              </v-col>
              <v-col>
                {{ $t("Helps.custom-product-7-2") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1"> </v-col>
              <v-col cols="3">
                {{ $t("Helps.custom-product-8-1") }}
              </v-col>
              <v-col>
                {{ $t("Helps.custom-product-8-2") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                2.
              </v-col>
              <v-col>
                {{ $t("Helps.custom-product-9-1") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
              </v-col>
              <v-col>
                {{ $t("Helps.custom-product-9-2") }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
              </v-col>
              <v-col>
                {{ $t("Helps.custom-product-9-3") }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
